@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0;
  outline: 0;

  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  display: none;
}

img {
  width: 100%;
  object-fit: cover;
}
dl,
ol,
ul {
  margin-bottom: 0.5rem;
}
:root {
  --color-bg: #ffffff;
  --text: #1e1e1e;
}

html {
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  display: none;
}
body {
  font-family: "Inter", sans-serif;
  background-color: var(--color-bg);
  line-height: 1.7;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

.landing_section {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../src/Assets/images/mask-group.jpg");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.coming_heading {
  color: var(--text);
  font-family: Inter;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.02em;
  text-align: center;
}
